window._ = require('lodash');
import Cookies from 'js-cookie';

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
        window.Popper = require("popper.js").default;
        window.$ = window.jQuery = require("jquery");

        require("bootstrap");
        require("slick-carousel");
        require("./jquery.pan.min-max-zoom");
        require("jquery.searchable");
        require("lazysizes");
        require("lazysizes/plugins/parent-fit/ls.parent-fit");

        // Smooth scroll
        $("a.mouse").click(function() {
            if (
                location.pathname.replace(/^\//, "") ==
                    this.pathname.replace(/^\//, "") &&
                location.hostname == this.hostname
            ) {
                var target = $(this.hash);
                target = target.length
                    ? target
                    : $("[name=" + this.hash.slice(1) + "]");

                if (target.length) {
                    $("html, body").animate(
                        {
                            scrollTop: target.offset().top - 100
                        },
                        1000
                    );
                    return false;
                }
            }
        });

        function isScrolled() {
            if ($(window).scrollTop() >= 40) {
                $("body").addClass("scrolled");
            } else {
                $("body").removeClass("scrolled");
            }
        }

        $(window).on("load scroll", function() {
            isScrolled();
        });

        function getContainerDistance($container) {
            return ($(window).width() - $container.outerWidth()) / 2;
        }

        /* debounce */
        (function($, sr) {
            // debouncing function from John Hann
            // http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
            var debounce = function(func, threshold, execAsap) {
                var timeout;

                return function debounced() {
                    var obj = this,
                        args = arguments;
                    function delayed() {
                        if (!execAsap) func.apply(obj, args);
                        timeout = null;
                    }

                    if (timeout) clearTimeout(timeout);
                    else if (execAsap) func.apply(obj, args);

                    timeout = setTimeout(delayed, threshold || 100);
                };
            };
            // smartresize
            jQuery.fn[sr] = function(fn) {
                return fn
                    ? this.bind("resize", debounce(fn))
                    : this.trigger(sr);
            };
        })(jQuery, "smartresize");

        const bps = {
            smartphone: {
                label: "xs",
                enter: 0,
                exit: 320
            },
            smartphone: {
                label: "sm",
                enter: 576,
                exit: 767
            },
            tablet: {
                label: "md",
                enter: 768,
                exit: 1024
            },
            desktop: {
                label: "lg",
                enter: 992,
                exit: 20000
            }
        };

        function onResize() {
            /* breakpoints helper classes on body */
            $("body").removeClass(function(index, css) {
                return (css.match(/\bbp-\S+/g) || []).join(" ");
            });

            if (
                window.matchMedia("(max-width: " + bps.smartphone.exit + "px)")
                    .matches
            ) {
                $("body").addClass("bp-" + bps.smartphone.label);
            }

            if (
                window.matchMedia("(min-width: " + bps.tablet.enter + "px)")
                    .matches &&
                window.matchMedia("(max-width: " + bps.tablet.exit + "px)")
                    .matches
            ) {
                $("body").addClass("bp-" + bps.tablet.label);
            }

            if (
                window.matchMedia("(min-width: " + bps.desktop.enter + "px)")
                    .matches
            ) {
                $("body").addClass("bp-" + bps.desktop.label);
            }

            /* orientation helper classes on body */
            var mql = window.matchMedia("(orientation: portrait)");

            // If there are matches, we're in portrait
            if (mql.matches) {
                // Portrait orientation
                if (!$("body").hasClass("portrait")) {
                    $("body").removeClass("landscape");
                    $("body").addClass("portrait");
                }
            } else {
                // Landscape orientation
                if (!$("body").hasClass("landscape")) {
                    $("body").removeClass("portrait");
                    $("body").addClass("landscape");
                }
            }

            if ($("body:not(.bp-xs) .carousel").length) {
                let $container = $(".section--features .container"),
                    $containerPaddingLeft = parseInt(
                        $container.css("paddingLeft")
                    );
                $(".carousel .slick-track").css(
                    "left",
                    getContainerDistance($container) +
                        $containerPaddingLeft +
                        "px"
                );
            }
        }

        // applico debounce al resize
        $(window).smartresize(function() {
            onResize();
        });

        // applico debounce al load
        $(window).on("load", function() {
            onResize();
        });
        //delete cookie function
        function delete_cookie( name ) {
            document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }

        if ($(".carousel").length) {

            let $carousel = $(".carousel"),
                options = {
                    slide: ".carousel-slide",
                    infinite: false,
                    arrows: false,
                    touchThreshold: 20,
                    mobileFirst: true,
                    rows: 0
                };

            options.responsive = [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4.5,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3.5,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 570,
                    settings: {
                        slidesToShow: 2.5,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 320,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                }
            ];

            if ($carousel.data("autoplay")) {
                options.autoplay = $carousel.data("autoplay");
            }

            if ($carousel.data("autoplayspeed")) {
                options.autoplaySpeed = $carousel.data("autoplayspeed");
            }

            $carousel.on("init", function(event, slick) {
                slick.$slides.css("height", slick.$slideTrack.height() + "px");
            });

            $carousel.slick(options);

            // Carousel nav
            if ($(".carousel__nav").length) {
                // PREV
                $(".carousel__arrow--prev").on("click", function(e) {
                    $(this)
                        .parents(".section--features")
                        .find(".carousel")
                        .slick("slickPrev");
                });

                // NEXT
                $(".carousel__arrow--next").on("click", function(e) {
                    $(this)
                        .parents(".section--features")
                        .find(".carousel")
                        .slick("slickNext");
                });
            }

            if( $( ".slider" ).length) {

				$( '.slider' ).each( function() {
					let $slider = $(this),
						options = {
							slide: ".slide",
							infinite: false,
							slidesToShow: 1,
							arrows: false,
							touchThreshold: 20,
							dots: true,
                            rows: false,
                            focusOnSelect: false
						};

                    $slider.slick(options);
                });

                $( '.slide' ).on( 'click', function(e){
                    e.preventDefault()
                })

            }

            // slider nav
            if ($(".slider__nav").length) {
                $(".slider__dot").on("click", function(e) {
                    $(this)
                        .parents(".slider")
                        .slick("slickGoTo", $(this).data("index"));
                });
            }
        }

        if ($(".cta").length) {
            $(".cta").on("click", function(e) {
                e.preventDefault();

                let $form = $(this).attr("href");

                !$("body").hasClass("modal-form-open")
                    ? $("body").addClass("modal-form-open")
                    : "";
                $($form).toggleClass("modal-form--show");
            });
        }

        if ($(".modal-form__close").length) {
            $(".modal-form__close").on("click", function(e) {
                e.preventDefault();

                let $form = $(this).attr("href");

                $("body").hasClass("modal-form-open")
                    ? $("body").removeClass("modal-form-open")
                    : "";
                $(this)
                    .parents(".modal-form")
                    .removeClass("modal-form--show");
            });
        }

        if ($(".product-catalog__link").length) {
            //prevent single product redirect
            $("body").on("click", ".product-catalog__link", function(e) {
                e.preventDefault();
            });

            $("body").on("click", ".product-catalog__link",
                function(e) {
                    e.preventDefault();

                    $(".product-catalog__link").parent().removeClass( 'is-opened' );
                    $(this).parent().addClass('is-opened');

                    // Fetch product
                    let data = {}
                    let url = $(this).attr('href');

                $.ajax({
                    type: "GET",
                    dataType: "json",
                    url: url,
                    data: data,
                    headers: {
                        'Authorization': 'Bearer ' + $('meta[name="api-token"]').attr('content'),
                        },
                })
                .done(function (response) {
                    console.log(response);
                    if(!$("body").hasClass("modal-product-open") ) {
                        $("body").addClass("modal-product-open");
                    }

                    $('#modal-product #product-title').text( response.name );
                    $('#modal-product #product-description').text(response.description);
                    $('#modal-product #product-code').text(response.code);
                    $('#modal-product #product-csp').text(response.percentage_acronyms_composition);
                    $('#modal-product #product-fd').text(response.fabric_dyeing);
                    $('#modal-product #product-weight').text(parseFloat(response.weight).toFixed(0));


                    let $slider = $(".slider");

                    $slider.empty();
                    console.log(response);

                    //fetch slider images
                    response.gallery_urls.forEach(function(img) {
                        let slide =
                            `<div class="slide">
                                <img class="img-fluid" src="${img.small}" alt="slide">
                                <a class="pan" data-big="${img['big']}" href="#">
                                    Zoom
                                </a>
                            </div>`;
                        $slider.append(slide);
                        $(".pan").pan(false);
                    });

                    //fetch slider zoom image
                    // if (response.zoom_image_url['big'] && response.zoom_image_url['small']) {
                    //     let lastSlide = `<div class="slide">
                    //         <img class="img-fluid" src="${response.zoom_image_url['small']}" alt="" >
                    //     </div>`;

                    //     $slider.append(lastSlide);

                    //     $slider.parent().append(`<a class="pan" data-big="${response.zoom_image_url['big']}" href="#">
                    //         Zoom
                    //     </a>`);

                    //     $(".pan").pan(false);
                    // }

                    let options = {
                            infinite: true,
                            slidesToShow: 1,
                            arrows: true,
                            touchThreshold: 20,
                            dots: true,
                            rows: false
                        };

                    $slider.slick(options);

                })
                .fail(function (xhr) {
                    console.log(xhr.responseText);
                });
            });
        }

        if ($(".modal-product__close").length) {
            $(".modal-product__close").on("click", function(e) {
                e.preventDefault();

                $("body").hasClass("modal-product-open")
                    ? $("body").removeClass("modal-product-open")
                    : "";

                if( ! $('.wl-feedback alert').hasClass('d-none') ) {
                      $('.wl-feedback .alert').addClass('d-none');
                }
                $(this).parents('.modal-product').find( '.slider' ).slick( 'unslick' );

            });
        }

        if ($(".modal-wishlist__close").length) {
            $(".modal-wishlist__close").on("click", function (e) {
                e.preventDefault();

                $("body").hasClass("modal-wishlist-open") ?
                    $("body").removeClass("modal-wishlist-open") :
                    "";
            });
        }
        //update user account details
        if ($("#modal-user").length) {
            $("#modal-user .form").on("click", ".form__submit", function(e) {
                e.preventDefault();

                //reset responses
                $('#modal-user .form__response').empty().addClass('d-none');
                //get form data
                let $form = $(this).parents('.form');
                let url = $form.attr('action');
                let data = $form.serialize();
                console.log(data);
                $.ajax({
                        type: "POST",
                        dataType: "json",
                        headers: {
                            'Authorization': 'Bearer ' + $('meta[name="api-token"]').attr('content'),
                            },
                        url: url,
                        data: data,
                    })
                    .done(function (response) {
                        console.log(response);

                        if (response.success) {
                            $('#modal-user .form__response--success')
                                .append('<li><small><strong class="text-success">' + response.success + '</strong></small></li>')
                                .removeClass('d-none');
                        } else {
                            $('#modal-user .form__response--error')
                            .append('<li><small><strong class="text-danger">' + response.error + '</strong></small></li>')
                            .removeClass('d-none');
                        }
                    })
                    .fail(function (xhr) {

                        console.log('xhr ' + xhr.responseText);
                    });
            });
        }

        if ($(".modal-user__close").length) {
            $(".modal-user__close").on("click", function (e) {
                e.preventDefault();

                $("body").hasClass("modal-user-open") ?
                    $("body").removeClass("modal-user-open") :
                    "";
            });
        }

        if ($(".form--modal").length) {
            $(".form--modal").on("submit", function(e) {
                e.preventDefault();

                let data = {},
                    $fields = $(this).serializeArray();

                jQuery.each($fields, function(i, field) {
                    data[field.name] = field.value;
                });

                // data["_token"] = $('meta[name="csrf-token"]').attr("content");
                // data["_method"] = "post";

                $.ajax({
                    type: "POST",
                    dataType: "json",
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                        'Authorization': 'Bearer ' + $('meta[name="api-token"]').attr('content'),
                        },
                    url: "/contact",
                    data: data
                })
                    .done(function(response) {
                        if (response.result == "success") {
                            $(".form--modal")
                                .parent()
                                .append(
                                    '<div class="alert alert-success" role="alert">' +
                                        response.output +
                                        "</div>"
                                );
                        } else {
                            $(".form--modal")
                                .parent()
                                .append(
                                    '<div class="alert alert-danger" role="alert">' +
                                        response.output +
                                        "</div>"
                                );
                        }
                    })
                    .fail(function(xhr) {
                        console.log(xhr.responseText);
                    });
            });
        }

        $("#menu-toggle").on("click", function(e) {
            e.preventDefault();

            $("body").toggleClass("menu-open");

            $(this).toggleClass("is-active");

            $(this).attr("aria-expanded", function(i, attr) {
                return (attr = "false" ? "true" : "false");
            });
        });

        $("#menu-close").on("click", function(e) {
            e.preventDefault();

            $("body").removeClass("menu-open");

            $("#menu-close").attr("aria-expanded", function(i, attr) {
                return attr == "true" ? "false" : "true";
            });

            $("#menu-toggle").removeClass("is-active");
        });

        $("#wishlist-toggle, #wishlist-toggle-mobile").on("click", function (e) {
            e.preventDefault();

            $("body").toggleClass("modal-wishlist-open");

            if( !Cookies.get('wishlist') || (Cookies.get('wishlist') === '[]') ) {
                // console.log(Cookies.get('wishlist'));
                $( '.modal-wishlist .alert-secondary' ).removeClass( 'd-none' );
                $('.modal-wishlist__body .delivery-address-field').addClass('d-none');
                // delete_cookie('wishlist');
                // $( '#send-samples' ).addClass( 'd-none' );
            } else {
                $( '.modal-wishlist .alert-secondary' ).addClass( 'd-none' );
                $('.modal-wishlist__body .delivery-address-field').removeClass('d-none');
                // if( $('#send-samples').hasClass( 'd-none' ) ) {
                //     $('#send-samples').removeClass( 'd-none' );
                // }
            }

            $(this).toggleClass("is-active");

            $(this).attr("aria-expanded", function (i, attr) {
                return (attr = "false" ? "true" : "false");
            });

            //$('.modal-wishlist__body .alert').addClass('d-none');
            $('.modal-wishlist__body #wishlist-sent').addClass('d-none');
            if (!Cookies.get('wishlist')) {
                $('.modal-wishlist__body #wishlist-empty').removeClass('d-none');
            }
        });

        $("#user-toggle, #user-toggle-mobile").on("click", function (e) {
            e.preventDefault();

            $("body").toggleClass("modal-user-open");

            $(this).toggleClass("is-active");

            $(this).attr("aria-expanded", function (i, attr) {
                return (attr = "false" ? "true" : "false");
            });
        });

        // $('body').on('click', '.panWrapper', function() {
        //     setTimeout(() => {
        //         $('.panWrapper').remove();
        //     }, 600);
        // });

        /*--------------------------------------------
		Cookies
		--------------------------------------------*/
        // function setCookie(cname, cvalue, exdays) {
        //     var d = new Date();
        //     d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        //     var expires = "expires=" + d.toUTCString();
        //     document.cookie = cname + "=" + cvalue + "; " + expires;
        // }

        // function getCookie(cname) {
        //     var name = cname + "=";
        //     var ca = document.cookie.split(";");
        //     for (var i = 0; i < ca.length; i++) {
        //         var c = ca[i];
        //         while (c.charAt(0) === " ") c = c.substring(1);
        //         if (c.indexOf(name) === 0)
        //             return c.substring(name.length, c.length);
        //     }
        //     return "";
        // }

        if( Cookies.get('wishlist') ) {
			console.log("cookie wl active");
		} else {
            Cookies.set('wishlist', '[]')
        }

		if( $(".add-wl").length ) {
            // This function fill wishlist modal with prefered product
            function fillWishlist() {
                if (Cookies.get('wishlist')) {
                    let $wishlist = $.parseJSON(Cookies.get('wishlist'));

                    $.each($wishlist,function() {
                        let $productColumnClone = $('.site-main .product-catalog[data-product-id="'+ this +'"]').parents('.product__column').clone();
                        let button = '<button class="product-catalog__dislike w-100" data-product-id="' + this +  '">Remove</button>';

                        $productColumnClone.find('.product-catalog').removeClass(['prefered', 'is-opened']).append(button);
                        $productColumnClone.appendTo('.modal-wishlist__body .row');

                    });

                }
            }

            function checkwishlist() {
                // This function adds an active state to any items it finds that are in the cookie.
                let $wishlist = $.parseJSON(Cookies.get('wishlist'));

                $(".site-main .product-catalog").each(function () {
                    var $this = $(this);
                    var $thisID = $this.data('product-id');

                    if (Cookies.get('wishlist')) {
                        $wishlist = $.parseJSON(Cookies.get('wishlist'));
                        var found = $.inArray($thisID, $wishlist);
                        if (found >= 0) {
                            $this.addClass('prefered');
                        } else {
                            $this.removeClass('prefered');
                        }
                    }
                });
            }

            $("body").on( 'click', '.add-wl', function(e) {
                e.preventDefault();

                let $productID = $('.product-catalog.is-opened').data('product-id'),
                    $shortlist = [],
                    $stringify = '',
                    $wishlist = '',
                    $this = $(this);

                //clean modal
                $('.modal-wishlist__body .row').empty();
                $this.next().find('.alert-success').addClass('d-none');
                $this.next().find('.alert-secondary').addClass('d-none');

                // If there is already a wishlist cookie saved..
                if (Cookies.get('wishlist')) {
                    // Read the cookie, convert it from a string to an array
                    $wishlist = $.parseJSON(Cookies.get('wishlist'));
                    // Check to see if the ID of the thing we just clicked lives in the array
                    let found = $.inArray( $productID, $wishlist );

                    if (found === -1) {
                        // If we didn't find the item we clicked on in the cookie, add it.
                        $wishlist.push($productID);
                        $this.next().find('.alert-success').removeClass('d-none');
                    } else {
                        $this.next().find('.alert-secondary').removeClass('d-none');
                    }

                    $stringify = JSON.stringify($wishlist);
                    // Save the cookie, call it wishlist
                    Cookies.set('wishlist', $stringify);

                    // If we couldn't see a cookie saved
                } else {
                    $shortlist.push($productID);
                    $(this).next().find('.alert-success').removeClass('d-none');

                    // Convert the data to a string, as Cookies can't store arrays
                    $stringify = JSON.stringify($shortlist);
                    // Save the cookie, call it wishlist
                    Cookies.set('wishlist', $stringify, {
                        expires: 365
                    });
                }

                // When the page is loaded, before doing anything just check to see if anything should have the active state added.
                checkwishlist();
                fillWishlist();

            });
            checkwishlist();
            fillWishlist();
        }

        if( $('.modal-wishlist').length ) {

            // $.ajaxSetup({
            //     headers: {
            //         'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            //         'Authorization': 'Bearer ' + $('meta[name="api-token"]').attr('content'),
            //         }
            // });
            $('.modal-wishlist__body').on( 'click', '.product-catalog__dislike', function(e) {
                e.preventDefault();
                let $thisID = $(this).data('product-id');
                let wishlist = $.parseJSON(Cookies.get('wishlist'));

                var index = wishlist.indexOf($thisID);
                if (index !== -1) wishlist.splice(index, 1);

                var $stringify = JSON.stringify(wishlist);
                Cookies.set('wishlist', $stringify);

                $(`.modal-wishlist .product-catalog[data-product-id=${$thisID}]`).parents('.product__column').remove();

                if( !Cookies.get('wishlist') || (Cookies.get('wishlist') === '[]') ) {
                    // console.log(Cookies.get('wishlist'));
                    $( '.modal-wishlist .alert-secondary' ).removeClass( 'd-none' );
                    $('.modal-wishlist__body .delivery-address-field').addClass('d-none');
                    // delete_cookie('wishlist');
                    // $( '#send-samples' ).addClass( 'd-none' );
                } else {
                    $( '.modal-wishlist .alert-secondary' ).addClass( 'd-none' );
                    $('.modal-wishlist__body .delivery-address-field').removeClass('d-none');
                    // if( $('#send-samples').hasClass( 'd-none' ) ) {
                    //     $('#send-samples').removeClass( 'd-none' );
                    // }
                }
            });

            $('.modal-wishlist').on('click', '#send-samples', function() {

                if (Cookies.get('wishlist') && (Cookies.get('wishlist') != '[]')) {
                    let $this = $(this);
                    console.log(Cookies.get('wishlist'));
                    $this.attr('disabled', true);
                    $('.modal-wishlist__spinner').removeClass('d-none');
                    
                    $(".modal-wishlist form").submit();

                }

            });

            $(".modal-wishlist form").on( "submit", function( event ) {
                event.preventDefault();

                var $sendBtn = $('.modal-wishlist #send-samples');

                if(document.querySelector('.modal-wishlist form').reportValidity()){
                    let data = {
                        productsIds: $.parseJSON(Cookies.get('wishlist')),
                        delivery_address: $('#delivery-address').val(),
                        delivery_zip: $('#delivery-zip').val(),
                        delivery_city: $('#delivery-city').val(),
                        delivery_country: $('#delivery-country').val(),
                    };
    
                    console.log(data);
                    // let url = "/api/wishlist";
                    // url += '?api_token=' + $('meta[name="api-token"]').attr('content');
                    // let apiToken = document.head.querySelector('meta[name="api-token"]');
    
                    $.ajax({
                        type: "GET",
                        dataType: "json",
                        headers: {
                            'Authorization': 'Bearer ' + $('meta[name="api-token"]').attr('content'),
                            },
                        url: "/api/wishlist",
                        data: data
                    })
                    .done(function (response) {
                        $('.modal-wishlist__spinner').addClass('d-none');
                        $sendBtn.removeAttr('disabled');
    
                        if (response.result === 'success') {
                            $('.modal-wishlist__body .row').empty();
                            delete_cookie('wishlist');
                            $('.modal-wishlist__body .delivery-address-field').addClass('d-none')
                            $('.modal-wishlist__body .alert').addClass('d-none')
                            $('.modal-wishlist__body #wishlist-sent').removeClass('d-none')
                        }
                        // console.log(response);
                    })
                    .fail(function (xhr) {
                        $('.modal-wishlist__spinner').addClass('d-none');
                        $sendBtn.removeAttr('disabled');
                        $('.modal-wishlist__body .alert').addClass('d-none')
                        $('.modal-wishlist__body #wishlist-error').removeClass('d-none')
    
                        console.log(xhr.responseText);
                    });

                }else{
                    $('.modal-wishlist__spinner').addClass('d-none');
                    $sendBtn.removeAttr('disabled');
                }

            });    

        }
        //disable enter in search form
        $('#search-form').on('keypress', '#search-input',
            function(e) {
              if (e.which == '13') {
                e.preventDefault();
              }
        });

        if( $( '.search-form' ).is( ':visible') ) {
            // let showingElements = $('.product__column');

            $( '#products' ).searchable({
                selector: '.product__column',
                childSelector: '.product-catalog__hidden',
                searchField: '#search-input',
                hide: function(element) {
                    $(element).addClass('d-none');
                },
                show: function(element) {
                    $(element).removeClass('d-none');
                },
                onSearchActive: function(element) {
                    setTimeout(function() {
                        if ($('.product__column').not('.d-none').length) {
                            $('#products .product__error').addClass('d-none');
                        } else {
                            $('#products .product__error').removeClass('d-none');
                        }
                    }, 100)
                }
            });
        }

    } catch (e) {
        console.log(e);
    }

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let token = document.head.querySelector('meta[name="csrf-token"]');
if (token) {
  window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
  } else {
  console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });
